.profile_pic_thumbnail{
    width: 2em;
    height: 2em;
    margin-left: 3%;
    text-align: center;
    margin-top: 3%;
    border-radius: 100%;
    background-color: #feb9ee;
}

.about_title{
    display: flex;
}

.about_verified_icon{
    position: absolute;
    margin-top: -3%;
    margin-left: 2%;
}

.about_description{
    margin-left: 12%;
    margin-top: 3%;
    width: 70%;
}
