.date_picker{
    margin-left: 35%;
    padding-left: 10%;
    width: 50%;
    background-color: aliceblue;
    border-radius: 10px;
}

.date_div{
    margin-top: 3%;
    margin-left: 35%;
    text-align: center;
    display: flex;
}

.filter{
    margin-top: 5%;
    margin-left: 10%;
}

.filter:hover{
    cursor: pointer;
}