.projects{
    min-height: 7em;
}

  
.poster_pic{
    position: absolute;
    /* border: 1px solid black; */
    width: 2em;
    height: 2em;
    margin-left: 3%;
    text-align: center;
    margin-top: 3%;
    border-radius: 100%;
    background-color: gray;
}

.poster_pic p{
    margin-top: 20%;
    font-weight: 700;
}

.post_title{
    position:absolute;
    margin-left: 12%;
    margin-top: 4.25%;
    font-weight: 600;
}

.post_description{
    margin-top: 1%;
    margin-left: 12%;
    width: 70%;
    padding-bottom: 2%;
    white-space: pre-line;
}

.post_quote{
    /* align-items: center;
    text-align: center; */
    margin-left: 12%;
    font-weight: 700;
}



.post_technologies{
    margin-left: 8%;
    padding-top: 3%;
}

.dates{
    color: rgb(122, 122, 122);
    font-weight: 400;
}

.post_picture{
    width: 80%;
    margin-left: 10%;
    padding-top: 3%;
}

.show_more_button:hover{
    color: rgb(94, 217, 255);
    cursor: pointer;
}

.show_more_button{
    font-size: 80%;
    color: rgb(122, 122, 122);
}

.post_details{
    margin-left: 7%;
    /* padding-top: 25%; */
    /* margin-bottom: -20%; */
    width: 70%;
}

.project_links{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-left: 15%;
}

.project_link:hover{
    color: rgb(94, 217, 255);
    cursor: pointer;
}

.project_icon{
    position: absolute;
    margin-top: -.15%;
}


/* Mobile size */
@media screen and (max-width: 1220px) {    
    .post_picture{
        margin-top: 5%;
    }

}