.header_username{
    color: white;
    text-align: left;
    height: 50px;
    font-size: 1.2em;
    width: 100%;
    background-color:rgba(30, 30, 30, 0.718);
    z-index: 3;
}
  
.header_username_text{
    margin-top: 3%;
    margin-left: 3%;
    font-weight: 700;
    opacity: 100%;
}

.header_image{
    width: 100%;
    margin-top: 50px;
}
  
.profile_pic{
    width: 20%;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    margin-left: -97%;
}
  
.fullname{
    font-size: 1.5em;
    margin-top: 12%;
    margin-left: 3%;
}
  
.github_link{
    display: flex;
    font-size: 1em;
    margin-top: -1.5%;
    margin-left: 3%;
    font-weight: 550;
    color: rgb(206, 206, 206);
    /* color: rgb(116, 116, 116); */

    /* width: 30%; */
}
  
.github_link:hover{
    cursor: pointer;
    color: rgb(94, 217, 255);
}

.verified_icon{
    position:absolute;
    margin-left: 1%;
}

.github_icon{
    /* position:absolute; */
    margin-top: -.25%;
}
.verified_message{
    margin-left: 5%;
    text-align: center;
    color: rgb(94, 217, 255);
}
  

.social_icon{
    margin-bottom: -3%;
    margin-right: 1%;
}
  
.social_link{
    font-size: 1em;
    margin-left: 3%;
    font-weight: 550;
    color: rgb(206, 206, 206);
    margin-right: -3%;
}
  


.summary{
    margin-top: 2%;
    margin-left: 3%;
    margin-bottom: 1.5%;
    font-size: 1em;
    display: flex;
}

.Typewriter{
    margin-left: 2%;
}

.socials{
    display: flex;
}

.nightmode_toggle{
    position: absolute;
    margin-top: -16%;
    right: .5%;
}

.sunnies{
    position: absolute;
    width: 12.5%;
    margin-left: -95%;
    z-index: 100;
    rotate: -14deg;
}

  /* Monitor size */
@media screen and (min-width: 430px) {
    
    .profile_pic{
        margin-top: 32%;
    }

    .header_username{
        position: absolute;
    }

    .sunnies{
        margin-top: 35%;
    }

}

/* Mobile size */
@media screen and (max-width: 1000px) {    
    .profile_pic{
        margin-top: 33%;
    }

    .header_username{
        position: fixed;
    }

    .social_link{
        width: 30%;
    }

    .sunnies{
        margin-top: 40%;
    }

}

@media screen and (min-width: 1000px) {    
    .profile_pic{
        margin-top: 29.5%;
    }

    .header_username{
        position: fixed;
    }

    .social_link{
        width: 20%;
    }

    .sunnies{
        margin-top: 37%;
    }

}
