
body{
  /* font-family: 'Inconsolata', monospace; */
  font-family: 'Work Sans', sans-serif;
  overflow: overlay;
}

.App{
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: rgba(198, 128, 170, 0.8);
  /* color: black; */
  color: white;
  min-height: 100%;
}

.link_text{
  color: rgb(116, 116, 116);
}

.dark_mode{
  background-color:rgba(0, 0, 0, 0.9);
  /* background-color: #6c86d3bb; */
  color: white;
}

.womeo_image:hover{
  cursor: pointer;
}

/* Monitor size */
@media screen and (min-width: 430px) {
  .App {
    left:50%;
    position:absolute;
    transform: translate(-50%, 0%);
    text-align: left;
    width: 40%;
  }

}

/* Mobile size */
@media screen and (max-width: 1000px) {

  .App{
    width:100%
  }
  
 
}